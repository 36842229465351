import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Box, Container, Heading, Flex } from "theme-ui"
import Breadcrumbs from "../components/breadcrumbs"
import { ArrowRightCircle } from "react-feather"
import { InboundLink, OutboundLink } from "../components/link"
import { getPagePath, getProductPath } from "../utils/path"
import ArrowRight from "../assets/images/arrow-right.svg"

const PageHero = ({
  page,
  image = undefined,
  familyCategory = undefined,
  productCategory = undefined,
  familyCategories = undefined,
  color = "light",
  pageContext = undefined,
}) => {
  const home = page.model.apiKey === "home_page"
  const lightModel =
    page.model.apiKey === "product" ||
    page.model.apiKey === "reference" ||
    page.model.apiKey === "article" ||
    page.model.apiKey === "news"

  return (
    <Box
      sx={{
        zIndex: 0,
      }}
    >
      {home ? (
        <>
          <Box
            sx={{
              display: ["none", "none", "block"],
              position: "relative",
              mb: [4],
              px: [0],
              pb: [10, 11, 11, 13],
              height: ["100%"],
              zIndex: 0,
              backgroundColor: "dark",
            }}
          >
            {image ? (
              <Box
                sx={{
                  position: "absolute",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  height: "100%",
                  width: "100%",
                  ".gatsby-image-wrapper": {
                    height: "100%",
                    width: "100%",
                  },
                }}
              >
                <GatsbyImage image={image.gatsbyImageData} />
              </Box>
            ) : (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: ["100%"],
                  width: "100%",
                  backgroundColor: "dark",
                  overflow: "hidden",
                }}
              ></Box>
            )}
            <Container
              sx={{
                pt: [5, 11],
                position: "relative",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  pt: [5, 4],
                  width: ["100%", "80%", "60%"],
                  fontSize: [5, 7],
                  fontFamily: "heading",
                  lineHeight: ["heading", "52px"],
                  fontWeight: "heading",
                  color: color,
                  mb: 3,
                }}
                dangerouslySetInnerHTML={{ __html: page.title }}
                color={color}
              />
              <Box sx={{ display: "inline-block", mt: [2] }}>
                <InboundLink
                  sx={{ display: "block" }}
                  variant="buttons.primary"
                  to={getPagePath(page.link.link, page.locale)}
                >
                  <Flex sx={{ alignItems: "center", alignContent: "center" }}>
                    <Flex
                      sx={{ alignItems: "center", alignContent: "center" }}
                      mr={[3]}
                    >
                      {page.link.anchor}
                    </Flex>
                    <Flex
                      variant="svg.arrow.light"
                      sx={{
                        alignItems: "center",
                        alignContent: "center",
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      <ArrowRight />
                    </Flex>
                  </Flex>
                </InboundLink>
              </Box>
            </Container>
          </Box>
          <Box
            sx={{
              display: ["block", "block", "none"],
              position: "relative",

              px: [0],
              pb: [10, 11, 11, 13],
              height: ["100%"],
              zIndex: 0,
              backgroundColor: "dark",
            }}
          >
            {image ? (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: ["100%"],
                  width: "100%",

                  ".gatsby-image-wrapper": {
                    height: ["100%"],
                    width: "100%",
                    img: {
                      objectFit: "contain",
                      objectPosition: ["bottom center"],
                    },
                  },
                  overflow: "hidden",
                }}
              >
                <GatsbyImage image={image.mobile} />
              </Box>
            ) : (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: ["100%"],
                  width: "100%",
                  backgroundColor: "dark",
                  overflow: "hidden",
                }}
              ></Box>
            )}
            <Container
              sx={{
                pt: [5, 11],
                position: "relative",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  pt: [5, 4],
                  width: ["100%", "80%", "60%"],
                  fontSize: [5, 7],
                  fontFamily: "heading",
                  lineHeight: ["heading", "52px"],
                  fontWeight: "heading",
                  color: color,
                  mb: 3,
                }}
                dangerouslySetInnerHTML={{ __html: page.title }}
                color={color}
              />
              <Box sx={{ display: "inline-block", mt: [2] }}>
                <InboundLink
                  sx={{ display: "block" }}
                  variant="buttons.primary"
                  to={getPagePath(page.link.link, page.locale)}
                >
                  <Flex sx={{ alignItems: "center", alignContent: "center" }}>
                    <Flex
                      sx={{ alignItems: "center", alignContent: "center" }}
                      mr={[3]}
                    >
                      {page.link.anchor}
                    </Flex>
                    <Flex
                      variant="svg.arrow.light"
                      sx={{
                        alignItems: "center",
                        alignContent: "center",
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      <ArrowRight />
                    </Flex>
                  </Flex>
                </InboundLink>
              </Box>
            </Container>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: ["none", "none", "block"],
              position: "relative",
              mb: 0,
              backgroundColor: "dark",
              pt: [lightModel ? 7 : 9, lightModel ? 7 : 7],
              pb: [6, lightModel ? 0 : 10],
              height: ["100%"],
              zIndex: 0,
            }}
          >
            {image ? (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: ["100%"],
                  width: "100%",
                  ".gatsby-image-wrapper": {
                    height: ["100%"],
                    width: "100%",
                  },
                  overflow: "hidden",
                }}
              >
                <GatsbyImage image={image.gatsbyImageData} />
              </Box>
            ) : (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: ["100%"],
                  width: "100%",
                  backgroundColor: color === "dark" ? "light" : "dark",
                  overflow: "hidden",
                }}
              ></Box>
            )}
            <Container
              sx={{
                pt: [5, lightModel ? 5 : 11, lightModel ? 5 : 11, 11],
                position: "relative",
                height: "100%",
              }}
            >
              <Heading as="h1" variant="h1" color={color} sx={{ width: "60%" }}>
                {page.title}
                <Box as="span" sx={{ fontSize: [2] }}>
                  {page.model.apiKey === "news_page"
                    ? `${
                        pageContext.currentPage > 1
                          ? " - Pagina " + pageContext.currentPage
                          : ""
                      }`
                    : ""}
                </Box>
              </Heading>
              <Breadcrumbs
                page={page}
                familyCategory={familyCategory}
                familyCategories={familyCategories}
                productCategory={productCategory}
              />
            </Container>
          </Box>
          <Box
            sx={{
              display: ["block", "block", "none"],
              position: "relative",
              mb: 0,
              backgroundColor: "dark",
              pt: [lightModel ? 7 : 9, lightModel ? 7 : 7],
              pb: [6, lightModel ? 0 : 10],
              height: ["100%"],
              zIndex: 0,
            }}
          >
            {image ? (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: ["100%"],
                  width: "100%",
                  ".gatsby-image-wrapper": {
                    height: ["100%"],
                    width: "100%",
                    objectFit: "contain",
                    objectPosition: ["bottom center"],
                  },
                  overflow: "hidden",
                }}
              >
                <GatsbyImage image={image.mobile} />
              </Box>
            ) : (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: ["100%"],
                  width: "100%",
                  backgroundColor: color === "dark" ? "light" : "dark",
                  overflow: "hidden",
                }}
              ></Box>
            )}
            <Container
              sx={{
                pt: [5, lightModel ? 5 : 11, lightModel ? 5 : 11, 11],
                position: "relative",
                height: "100%",
              }}
            >
              <Heading as="h1" variant="h1" color={color}>
                {page.title}
                <Box as="span" sx={{ fontSize: [2] }}>
                  {page.model.apiKey === "news_page"
                    ? `${
                        pageContext.currentPage > 1
                          ? " - Pagina " + pageContext.currentPage
                          : ""
                      }`
                    : ""}
                </Box>
              </Heading>
              <Breadcrumbs
                page={page}
                familyCategory={familyCategory}
                familyCategories={familyCategories}
                productCategory={productCategory}
              />
            </Container>
          </Box>
        </>
      )}
    </Box>
  )
}

export default PageHero
