import React from "react"
import { graphql } from "gatsby"
import { Container, Heading, Box, Flex } from "@theme-ui/components"
import Layout from "../components/layout"
import { getHomePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import PageHero from "./pageHero"
import RichContentStructuredText from "../components/richContentStructuredText"
import { MagicLink } from "../utils/magicLink"
import FamilyGrid from "../components/blocks/familyGrid"
import DoubleExternalProduct from "../components/blocks/doubleExternalProduct"
import ContactForm from "../components/blocks/contactForm"
import NewsSection from "../components/blocks/newsSection"
import SectorsSection from "../components/blocks/sectorsSection"
import ServicesSection from "../components/blocks/servicesSection"
import FeaturedProductsCollection from "../components/blocks/featuredProductsCollection"
import ArrowRight from "../assets/images/arrow-right.svg"
import { i18nContext } from "../context/i18nContext"
import FilterMetaTagDescription from "../utils/filterMetaTagDescription"
import FeaturedProduct from "../components/blocks/featuredProduct"

const Home = ({
  data: { page, site, articles, familyCategories, productCategories },
}) => {
  const favicon = useFavicon().site.faviconMetaTags

  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getHomePath(locale),
    }
  })
  console.log(page.heroImage)
  const ldJson = {
    "@context": "https://schema.org/",
    "@type": "Organization",
    name: "Socaf S.p.a.",
    url: "https://socaf.it/",
    logo: "https://www.datocms-assets.com/60899/1644399196-logo.svg",
    email: "mailto:web@socaf.it",
    description: "Socaf - Macchine per la pulizia industriale",
    address: {
      "@type": "PostalAddress",
      postalCode: "24046",
      streetAddress: "Via Trieste, 14",
      addressCountry: "IT",
      addressRegion: "Lombardia",
      addressLocality: "Osio Sotto",
    },
    telephone: "+390354876054",
    sameAs: [
      "https://www.facebook.com/socafspa/",
      "https://twitter.com/SocafSpa",
      "https://it.linkedin.com/company/socaf-s-p-a-",
      "https://www.youtube.com/user/SOCAFspa",
    ],
  }

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms
        seo={FilterMetaTagDescription(page.seoMetaTags)}
        favicon={favicon}
      >
        <html lang={page.locale} />
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </HelmetDatoCms>
      <PageHero page={page} image={page.heroImage} />
      {page.content.map(block => (
        <Box as="section" key={block.id} sx={{ zIndex: 5 }}>
          {block.model.apiKey === "text_block" && (
            <Box
              mt={[0, 0, -10, -11]}
              sx={{
                zIndex: 5,
                position: "relative",
                background:
                  "linear-gradient(90deg, #EA0029 45%, rgba(0, 0, 0, 0) 40%)",
                "&::before": {
                  content: "''",
                  height: ["100%", "100%", "80%", "60%", "50%"],
                  width: "100%",
                  position: "absolute",
                  bottom: 0,
                  zIndex: -1,
                  backgroundColor: "primary",
                },
              }}
            >
              <Container>
                <Box
                  sx={{
                    width: ["100%", "100%", "40%", "35%"],
                    backgroundColor: "primary",
                  }}
                >
                  <Heading as="p" variant="h2" sx={{ color: "light" }}>
                    {block.title}
                  </Heading>
                  <Box sx={{ p: { color: "light" }, pt: [3] }}>
                    <RichContentStructuredText text={block.body} />
                  </Box>
                  <Box sx={{ display: "inline-block", mt: [5] }}>
                    <MagicLink
                      sx={{
                        display: "block",
                        border: "1px solid",
                        borderColor: "light",
                      }}
                      variant="buttons.primary"
                      item={block.link}
                    >
                      <Flex
                        sx={{ alignItems: "center", alignContent: "center" }}
                      >
                        <Flex
                          sx={{ alignItems: "center", alignContent: "center" }}
                          mr={[3]}
                        >
                          {block.link.anchor}
                        </Flex>
                        <Flex
                          variant="svg.arrow.light"
                          sx={{
                            alignItems: "center",
                            alignContent: "center",
                            width: "24px",
                            height: "24px",
                          }}
                        >
                          <ArrowRight />
                        </Flex>
                      </Flex>
                    </MagicLink>
                  </Box>
                </Box>
              </Container>
            </Box>
          )}
          {block.model.apiKey === "family_grid" && (
            <FamilyGrid
              title={block.title}
              subtitle={block.subtitle}
              items={block.categories}
            />
          )}
          {block.model.apiKey === "double_external_product" && (
            <DoubleExternalProduct items={block.externalProducts} />
          )}

          {block.model.apiKey === "sectors_section" && (
            <SectorsSection kicker={block.kicker} sectors={block.sectors} />
          )}
          {block.model.apiKey === "services_section" && (
            <ServicesSection
              title={block.title}
              kicker={block.kicker}
              description={block.description}
              services={block.services}
            />
          )}
          {block.model.apiKey === "contact_form" && (
            <i18nContext.Consumer>
              {t => (
                <ContactForm
                  title={block.title ? block.title : t.form.title}
                  subtitle={block.subtitle ? block.subtitle : t.form.subtitle}
                  description={
                    block.newsletterDescription
                      ? block.newsletterDescription
                      : t.form.newsletterDescription
                  }
                />
              )}
            </i18nContext.Consumer>
          )}
          {block.model.apiKey === "featured_product" && (
            <FeaturedProduct
              kicker={block.kicker}
              title={block.title}
              subtitle={block.subtitle}
              product={block.product}
              familyCategories={familyCategories}
              productCategories={productCategories}
              fullWidth
            />
          )}
          {block.model.apiKey === "featured_products_collection" && (
            <FeaturedProductsCollection
              kicker={block.kicker}
              title={block.title}
              products={block.products}
              familyCategories={familyCategories}
              productCategories={productCategories}
            />
          )}
          {block.model.apiKey === "news_section" && (
            <NewsSection
              locale={page.locale}
              kicker={block.kicker}
              articles={articles.nodes}
            />
          )}
        </Box>
      ))}
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomeQuery($id: String!, $locale: String!) {
    page: datoCmsHomePage(id: { eq: $id }) {
      id
      title
      locale
      link {
        ... on DatoCmsInternalLink {
          id
          anchor
          locale
          model {
            apiKey
          }
          link {
            ... on DatoCmsNewsPage {
              ...NewsDetails
            }
            ... on DatoCmsReferencesPage {
              ...ReferencesDetails
            }
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
            ... on DatoCmsArticle {
              ...ArticleDetails
              ...ArticleAllSlugLocales
            }
          }
        }
      }
      heroImage {
        gatsbyImageData(
          width: 1920
          placeholder: NONE
          forceBlurhash: false
        )
        mobile: gatsbyImageData(
          placeholder: NONE
          forceBlurhash: false
          imgixParams: {
            fit: "crop"
            ar: "1 : 1"
            h: "400"
          }
        )
      }
      content {
        ... on DatoCmsTextBlock {
          id
          title
          darkBackground
          model {
            apiKey
          }
          alignLeftOrTop
          body {
            value
          }
          link {
            ... on DatoCmsInternalLink {
              id
              anchor
              locale
              model {
                apiKey
              }
              link {
                ... on DatoCmsNewsPage {
                  ...NewsDetails
                }
                ... on DatoCmsReferencesPage {
                  ...ReferencesDetails
                }
                ... on DatoCmsPage {
                  ...PageDetails
                  ...PageTreeParent
                  ...AllSlugLocales
                }
                ... on DatoCmsArticle {
                  ...ArticleDetails
                  ...ArticleAllSlugLocales
                }
              }
            }
          }
        }
        ... on DatoCmsFamilyGrid {
          id
          title
          subtitle
          categories {
            ... on DatoCmsProductFamily {
              id
              slug
              locale
              thumbnailImage {
                gatsbyImageData(
                  width: 800
                  placeholder: NONE
                  forceBlurhash: false
                )
              }
              shortTitle
              shortDescription
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsDoubleExternalProduct {
          id
          model {
            apiKey
          }
          externalProducts {
            id
            title
            subtitle
            backgroundColor {
              hex
            }
            body {
              value
            }
            link {
              ... on DatoCmsInternalLink {
                link {
                  ... on DatoCmsPage {
                    ...PageDetails
                    ...PageTreeParent
                    ...AllSlugLocales
                  }
                }
              }
            }
            image {
              url
              gatsbyImageData(
                width: 800
                placeholder: NONE
                forceBlurhash: false
                imgixParams: {
                  blendColor: "#212C30"
                  blendMode: "multiply"
                  blendAlpha: 60
                }
              )
            }
          }
        }
        ... on DatoCmsNewsSection {
          id
          kicker
          model {
            apiKey
          }
        }
        ... on DatoCmsSectorsSection {
          id
          kicker
          sectors {
            id
            body {
              value
            }
            slug
            locale
            title
            shortTitle
            subtitle
            heroImage {
              gatsbyImageData(
                width: 1920
                placeholder: NONE
                forceBlurhash: false
                imgixParams: {
                  blendColor: "#212C30"
                  blendMode: "multiply"
                  blendAlpha: 60
                }
              )
            }
            ...AllSlugLocales
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsServicesSection {
          id
          title
          kicker
          description
          services {
            id
            body {
              value
            }
            title
            subtitle
            label
            locale
            link {
              ... on DatoCmsInternalLink {
                id
                anchor
                locale
                model {
                  apiKey
                }
                link {
                  ... on DatoCmsNewsPage {
                    ...NewsDetails
                  }
                  ... on DatoCmsReferencesPage {
                    ...ReferencesDetails
                  }
                  ... on DatoCmsPage {
                    ...PageDetails
                    ...PageTreeParent
                    ...AllSlugLocales
                  }
                  ... on DatoCmsArticle {
                    ...ArticleDetails
                    ...ArticleAllSlugLocales
                  }
                }
              }
              ... on DatoCmsExternalLink {
                id
                anchor
                url
                model {
                  apiKey
                }
              }
            }
            image {
              url
              gatsbyImageData(
                width: 800
                placeholder: NONE
                forceBlurhash: false
              )
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsFeaturedProduct {
          kicker
          title
          subtitle
          product {
            ...ProductPageDetails
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsFeaturedProductsCollection {
          kicker
          title
          products {
            ...ProductPageDetails
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          id
          model {
            apiKey
          }
        }
      }

      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      model {
        apiKey
      }
    }

    productCategories: allDatoCmsProductCategory(
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        slug
        title
        locale
        products {
          id
          title
          slug
          locale
        }
        image {
          gatsbyImageData(width: 1920, placeholder: NONE, forceBlurhash: false)
        }
      }
    }

    familyCategories: allDatoCmsProductFamily(
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        title
        locale
        slug
        categories {
          slug
          id
          title
          products {
            id
            title
            slug
          }
        }
        model {
          apiKey
        }
      }
    }

    articles: allDatoCmsArticle(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      limit: 6
    ) {
      nodes {
        ...ArticleDetails
        ...ArticleAllSlugLocales
        ...ArticleMeta
      }
    }
    site: datoCmsSite {
      locales
    }
  }
`
