import React from "react"
import { Box, Container, Grid, Heading, Text, Flex } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import FamilyThumb from "../familyThumb"
import RichContentStructuredText from "../richContentStructuredText"
import { MagicLink } from "../../utils/magicLink"
import { ArrowRightCircle } from "react-feather"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui"
import ArrowRight from "../../assets/images/arrow-right.svg"
import { getServicePath } from "../../utils/path"
import { InboundLink } from "../link"

const ServicesSection = ({ title, kicker, description, services }) => {
  const dark = getColor(themeUiTheme, "dark")
  return (
    <Box>
      <Container sx={{ my: [4] }}>
        <Box>
          <Text as="div" variant="sectionTitle">
            <Flex as="div">
              <span>{kicker}</span>
            </Flex>
          </Text>
        </Box>
        <Box sx={{ py: [2, 4, 7], pt: [7] }}>
          <Grid columns={["1fr", "1fr", ".25fr .75fr"]} gap={[2, 2, 4, 8]}>
            <Box>
              <Heading as="div" variant="sectionTitle">
                <Box as="h2">{title}</Box>
              </Heading>
              <Text
                as="p"
                sx={{ letterSpacing: "-0.47px", lineHeight: "1.4", pr: [2] }}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Box>
            <Box sx={{ py: [2, 4] }}>
              <Box as="ul" sx={{ p: [0] }}>
                {services.map(service => (
                  <Box
                    as="li"
                    sx={{
                      listStyle: "none",
                      py: [3],
                      borderTop: "1px solid",
                      borderColor: "primary",
                      ":last-child": {
                        borderBottom: "1px solid",
                        borderColor: "primary",
                      },
                      a: {
                        textDecoration: "none",
                      },
                    }}
                  >
                    {service.link && service.link.link && (
                      <InboundLink
                        to={getServicePath(
                          service.link.link,
                          service.link.link.locale
                        )}
                      >
                        <Flex
                          sx={{
                            alignItems: "center",
                            justifyContent: "space-between!important",
                          }}
                        >
                          <Flex sx={{ alignItems: "center" }}>
                            <Box sx={{ pr: [4] }}>
                              {service.image && service.image.url && (
                                <Box
                                  sx={{
                                    backgroundColor: "dark",
                                    mask: `url(${service.image.url}) no-repeat`,
                                    "-webkit-mask": `url(${service.image.url}) no-repeat`,
                                    "mask-size": "contain",
                                    "-webkit-mask-size": "contain",
                                    width: "55px",
                                    height: "55px",
                                  }}
                                ></Box>
                              )}
                            </Box>
                            <Heading as="h3" variant="h4" sx={{ m: 0 }}>
                              {service.title}
                            </Heading>
                          </Flex>
                          <Flex
                            variant="svg.arrow.dark"
                            sx={{
                              alignItems: "center",
                              alignContent: "center",
                              width: "20px",
                              height: "20px",
                              svg: {
                                rect: {
                                  width: ["10px", "10px", "20px", "20px"],
                                  height: ["0px", "0px", "20px", "20px"],
                                },
                              },
                            }}
                          >
                            <ArrowRight />
                          </Flex>
                        </Flex>
                      </InboundLink>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default ServicesSection
